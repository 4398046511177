import logo from './logo.svg';
import './App.css';
import LandingPageNew from './LandingPageNew/LandingPageNew';
import AppRoutes from './LandingPageNew/AppRoutes/AppRoutes';

function App() {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
