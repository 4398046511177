import React from 'react'
import "./DownloadApp.scss"
import { image } from "../utils/common/image"
import DownloadStores from '../DownloadStores/DownloadStores'
const DownloadApp = () => {
    return (
        <div className='DownloadApp_main'>
            <div className='DownloadApp_leftSide'>
                <div className='downloadApp_heading-container'>
                    <h1 className='downloadApp_heading'>Download <br /> AdSimpl App</h1>
                </div>
                <div className='downloadApp_desc-container'>
                    <p className='downloadApp_desc'>Transform your advertising experience with the AdSimpl app! Create eye-catching ads, schedule them for maximum impact, and manage your campaigns effortlessly—all from your mobile device. Whether you're a small business or a large agency, AdSimpl empowers you to connect with your audience like never before. Download the app today and take your advertising to the next level!
                    </p>
                </div>
                <div className='downloadApp_store_container'>
                    <DownloadStores />
                </div>
            </div>

            <div className='DownloadApp_rightSide'>
                <div className='image_Container1'>
                    <img className='image_1' src={image.myAdsPhone} />
                    <div className='image_Container2'>
                        <img className='image_2' src={image.paymentPhone} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadApp
