import React from 'react'
import "./DownloadStores.scss"
import { image } from "../utils/common/image";

const DownloadStores = () => {
    return (

        <div className='stores'>
            <div className='appStore'>
                <div>
                    <img src={image.AppleIcon} />
                </div>
                <div>
                    <p>Download on the <br /><span> App Store</span> </p>
                </div>
            </div>
            <div className='playStore'>
                <div>
                    <img src={image.playStoreIcon} />
                </div>
                <div>
                    <p>Get it on <br /><span> Google Play</span> </p>
                </div>
            </div>

        </div>
    )
}

export default DownloadStores
