import React from 'react'
import "./OurClients.scss"
import { image } from "../utils/common/image"

const OurClients = () => {
    return (

        <div id='ourClients' className='ourClients'>
            <div className='ourClients_container'>
                <div className='backgroundAnimation'>
                    <img src={image.BackgroundAnimation} />
                </div>
                <div className='ourClients_main'>
                    <h1 className='ourClients_heading'>Our Clients</h1>
                    <p className='ourClients_description'>At AdSimpl, we take pride in partnering with a diverse range of clients who trust us to enhance their advertising efforts. <br /> Here are just a few of the businesses that have experienced the power of our platform:
                    </p>
                </div>
                {/** 
                <div className='clientList'>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client1} />
                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client4} />
                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client2} />

                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client4} />
                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client4} />
                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client3} />
                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client4} />
                    </div>
                    <div className='client_box'>
                        <img className='clientImage' src={image.Client2} />
                    </div>

                </div>
*/}
                <div className='semiCircle2'>
                    <img src={image.SemiCircle} />
                </div>
            </div>
        </div>
    )
}

export default OurClients
