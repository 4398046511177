export const image = {
    HomeImage: require('../../assests/Images/homeLP.png'),
    PhoneImage: require('../../assests/Images/PhoneImageLP.png'),
    Linkdin: require('../../assests/Images/linkdin.png'),
    Facebook: require('../../assests/Images/facebook.png'),
    Twitter: require('../../assests/Images/twitter.png'),
    Instagram: require('../../assests/Images/Instagram.png'),
    BackgroundAnimation: require('../../assests/Images/backgroundAnimation.png'),
    Client1: require('../../assests/Images/client1.png'),
    Client2: require('../../assests/Images/Client2.png'),
    Client3: require('../../assests/Images/Client3.png'),
    Client4: require('../../assests/Images/Client4.png'),
    Profile: require('../../assests/Images/profile.png'),
    FinishIcon: require('../../assests/Images/finishIcon.png'),
    AddIcon: require('../../assests/Images/addIcon.png'),
    CancelIcon: require('../../assests/Images/Cancel.png'),
    ContactUSArrow: require('../../assests/Images/contactUs Arrow.png'),
    Call: require('../../assests/Images/call.png'),
    Email: require('../../assests/Images/email.png'),
    Location: require('../../assests/Images/location.png'),
    AdSimplLogo: require('../../assests/Images/AdSimple Logo.png'),
    RocketUpIcon: require('../../assests/Images/upIconRocket.png'),
    FacebookFooter: require('../../assests/Images/facebookFooter.png'),
    InstagramFooter: require('../../assests/Images/instagramFooter.png'),
    TwitterFooter: require('../../assests/Images/twitterFooter.png'),
    LinkdinFooter: require('../../assests/Images/linkdinFooter.png'),
    SemiCircle: require('../../assests/Images/semiCircle.png'),
    LineAnimation: require('../../assests/Images/lineAnimation.png'),
    AppleIcon: require('../../assests/Images/appleIcon.png'),
    playStoreIcon: require('../../assests/Images/playStore.png'),
    myAdsPhone: require('../../assests/Images/myAdsPhone.png'),
    scheduleMangerPhone: require('../../assests/Images/scheduleManagerPhone.png'),
    adDetailsPhone: require('../../assests/Images/adDetailsPhone.png'),
    paymentPhone: require('../../assests/Images/paymentPhone.png'),
    AdSimplLogoPhone: require('../../assests/Images/AdSimplLogoPhone.png'),
    userImage: require('../../assests/Images/UserImage.png'),
}