import React from 'react'
import LandingPageNew from '../LandingPageNew'
import { Route, Routes } from 'react-router-dom'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<LandingPageNew />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
    )
}

export default AppRoutes