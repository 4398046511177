import React from 'react';
import "./Testimonials.scss";
import { image } from "../utils/common/image";

const testimonialsData = [
    {
        name: "Mihir",
        role: "Owner of Cafe Bliss",
        description: "As a small business owner, I was looking for a platform that could simplify the ad creation process, and AdSimpl exceeded my expectations. The interface is intuitive, and the Schedule Manager allows me to target my audience at the right times. It’s made a huge difference in our engagement and reach!.",
        img: image.Profile,
    },
    {
        name: "Gaurav",
        role: "Manager at Trendsetters Apparel",
        description: "AdSimpl has completely transformed the way our agency handles ad campaigns for clients. Being able to create customized ads and manage them all from one platform has saved us so much time.",
        // img: image.Profile,
    },
    {
        name: "Jignesh",
        role: "Founder of Urban Fit Gym",
        description: "The payment process with AdSimpl is incredibly smooth. I can quickly finalize campaigns without worrying about security or any hiccups. Plus, the ability to preview ads before making a purchase gives me peace of mind, knowing exactly what will go live.",
        // img: image.Profile,
    },
    {
        name: "Saurabh",
        role: "CEO of AdWave Agency",
        description: "AdSimpl has made it so easy to create stunning ads that capture our brand’s message. I can include all the details I need, and the platform’s flexibility allows me to tailor ads to specific locations and audiences!.",
        img: image.Profile,
    },
];

const Testimonials = () => {
    return (
        <div id='testimonials' className='testimonials'>
            <div className='testimonials_main'>
                <h1 className='testimonials_heading'>Testimonials</h1>
                <p className='testimonials_description'>Discover what our clients are saying about their experiences with AdSimpl. Our platform has empowered businesses to elevate their advertising strategies, achieve impressive results, and connect with their audiences like never before. Read on to see how AdSimpl is making a difference for our valued clients!</p>
            </div>

            <div className='testimonialList'>
                {testimonialsData.map((testimonial, index) => (
                    <div key={index} className='testimonialList_box'>
                        <div className='testimonialContent'>
                            <div className='testimonialContent_description'>
                                <p>{testimonial.description}</p>
                            </div>
                            <div className='testimonialContent_profile'>
                                <div className='profile_Img'>
                                    {testimonial.img === undefined || testimonial.img === "" ? <img src={image.userImage} /> : <img src={testimonial.img} alt={testimonial.name} />}
                                </div>
                                <div className='profile_details'>
                                    <h2>{testimonial.name}</h2>
                                    <p>{testimonial.role}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className='finishIcon'>
                <img src={image.FinishIcon} alt="Finish Icon" />
            </div>
        </div>
    );
};

export default Testimonials;
