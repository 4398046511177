import React, { useState } from 'react';
import "./Faqs.scss";
import { image } from "../utils/common/image";

const faqData = [
    {
        question: "How does AdSimpl make ad creation easier?",
        answer: "AdSimpl provides an easy-to-use platform for designing, scheduling, and managing ads. Customize your ads, set display times and locations, and preview them before they go live—all in one place.",
    },
    {
        question: "Can I target specific locations with my ads?",
        answer: "AdSimpl provides an easy-to-use platform for designing, scheduling, and managing ads. Customize your ads, set display times and locations, and preview them before they go live—all in one place.",
    },
    {
        question: "How secure is the payment process on AdSimpl?",
        answer: "AdSimpl offers a secure, encrypted payment process. You can preview ads before purchase and track payment history directly from your account.",
    },
    {
        question: "What can I do if my ad isn't approved right away?",
        answer: "If your ad isn't approved immediately, don’t worry! AdSimpl provides a user-friendly dashboard where you can view the current status of each ad under the “My Ads” section.",
    },

];

const Faqs = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const toggleFaq = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div id='faq' className='faqs'>
            <div className='faqs_main'>
                <h1 className='ourClients_heading'>FAQ's</h1>
            </div>
            <div className='faqContainer'>
                {faqData.map((faq, index) => (
                    <div key={index} className='faq' onClick={() => toggleFaq(index)}>
                        <div className='faq-question' >
                            <span>{activeIndex === index ? <img className='cancel' src={image.CancelIcon} /> : <img className='add' src={image.AddIcon} />}</span>
                            <h3>{faq.question}</h3>
                        </div>
                        {activeIndex === index && (
                            <div className='faq-answer'>
                                <div className='faq-answer_box'>
                                    <p>{faq.answer}</p>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className='semiCircle'>
                <img src={image.SemiCircle} />
            </div>

            <div className='lineAnimation'>
                <img src={image.LineAnimation} />
            </div>
        </div>
    );
}

export default Faqs;
