import React from 'react'
import "./AboutUsLandingPage.scss"
import { image } from "../utils/common/image";
import Button from '../atoms/Button/Button';

const AboutUsLandingPage = () => {
    return (
        <div id='about' className='AboutUsLandingPage_parent'>
            <img src={image.BackgroundAnimation} className='backgroundImage' alt='background animation' />
            <div className='AboutUsLandingPage_main'>
                <div className='AboutUsLandingPage_container'>
                    <div className='lineAnimation'>
                        <img src={image.LineAnimation} />
                    </div>
                    <h1 className='aboutus_heading'>About us</h1>
                    <p className='aboutus_description'> Welcome to AdSimpl, where creativity meets technology to bring your advertising vision to life. We are passionate about helping businesses and agencies connect with their audiences more effectively. Our mission is to simplify the process of creating, managing, and displaying ads across various digital screens. At AdSimpl, we believe that great advertising is more than just creating an ad; it's about making sure that message reaches the right people, at the right time, and on the right device. With this philosophy, we’ve built a platform that provides seamless integration, allowing you to manage your ads from anywhere with ease.
                    </p>
                </div>

                <div className='AboutUsLandingPage_container_1'>
                    <div className='aboutUsLeft'>
                        <div className='aboutUsLeft_Box'>
                            <div className='aboutUsLeft_Phone'>
                                <img src={image.adDetailsPhone} alt='phoneImage' />
                            </div>
                        </div>
                    </div>
                    <div className='aboutUsRight'>
                        <h1>Ad Details:</h1>
                        <p>Ensure your ads are precise, engaging, and reach the right audience with detailed ad information. AdSimpl allows you to include essential details like address, contact information, and category to create ads that are comprehensive and targeted. With rich ad details, you ensure that your message resonates with the right viewers, enhancing your campaign's effectiveness and reach.
                        </p>
                        <Button className='learnMoreButton' buttonTitle={"Learn More"} />
                    </div>
                </div>


                <div className='AboutUsLandingPage_container_2'>
                    <div className='aboutUsRight'>
                        <h1>Schedule Manager:   </h1>
                        <p>Take full control of when and where your ads are seen with AdSimpl's Schedule Manager. Easily design and schedule ads that fit your unique needs—whether it’s a simple promotional banner or a custom-tailored creation. With just a few clicks, you can choose your ad design, craft a compelling message, and set the exact time and location for your ads to appear. This flexibility ensures that your ads are always displayed at the right time, to the right audience—maximizing their impact.
                        </p>
                        <Button className='learnMoreButton' buttonTitle={"Learn More"} />
                    </div>
                    <div className='aboutUsLeft'>
                        <div className='aboutUsLeft_Box'>
                            <div className='aboutUsLeft_Phone'>
                                <img src={image.scheduleMangerPhone} alt='phoneImage' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='AboutUsLandingPage_container_3'>
                    <div className='aboutUsLeft'>
                        <div className='aboutUsLeft_Box'>
                            <div className='aboutUsLeft_Phone'>
                                <img src={image.paymentPhone} alt='phoneImage' />
                            </div>
                        </div>
                    </div>
                    <div className='aboutUsRight'>
                        <h1>Payment: </h1>
                        <p> Experience a hassle-free payment process with AdSimpl. Our platform ensures a smooth and secure transaction for every ad purchase. Before finalizing your ad, you can preview the design to ensure it meets your standards, giving you complete confidence in your campaign. Say goodbye to complicated payment procedures—AdSimpl makes it easy to get your campaigns up and running, so you can focus on what matters most: growing your brand.
                        </p>
                        <Button className='learnMoreButton' buttonTitle={"Learn More"} />
                    </div>
                </div>

                <div className='AboutUsLandingPage_container_2'>
                    <div className='aboutUsRight'>
                        <h1>My Ads: </h1>
                        <p> Stay on top of your advertising campaigns with AdSimpl's My Ads feature. This intuitive dashboard lets you view, manage, and track all your ads in one convenient place. Keep an eye on the approval status of each ad, make adjustments as needed, and ensure your ads are performing at their best. With AdSimpl, you remain in full control of your campaigns, allowing you to optimize your strategy and achieve the best results for your business.
                        </p>
                        <Button className='learnMoreButton' buttonTitle={"Learn More"} />
                    </div>
                    <div className='aboutUsLeft'>
                        <div className='aboutUsLeft_Box'>
                            <div className='aboutUsLeft_Phone'>
                                <img src={image.myAdsPhone} alt='phoneImage' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='semiCircle'>
                <img src={image.SemiCircle} />
            </div>


        </div>
    )
}

export default AboutUsLandingPage
