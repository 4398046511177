import React from 'react';
import './Footer.scss';
import { image } from '../utils/common/image';

const Footer = () => {
    return (
        <div id='footer' className='footer'>
            <div className="footer_main">
                <img className="AdSimpleLogo" src={image.AdSimplLogo} />
                <div className="appLogo">
                    <h1>ADSIMPL</h1>
                    <p>Advertising is the art of connecting your message to the right audience, at the right time!.</p>
                    <div className="socialmediaIcon">

                        <div className='socialmediaIcon_subContainer'>
                            <img src={image.FacebookFooter} alt="Facebook" />
                        </div>
                        <div className='socialmediaIcon_subContainer'>
                            <img src={image.InstagramFooter} alt="instagram" />
                        </div>
                        <div className='socialmediaIcon_subContainer'>
                            <img src={image.LinkdinFooter} alt="linkdin" />
                        </div>
                        <div className='socialmediaIcon_subContainer'>
                            <img src={image.TwitterFooter} alt="twitter" />
                        </div>

                    </div>
                </div>
                <div className="usefulLinks">
                    <h1>Useful Links</h1>
                    <div className="usefulLinks_section">
                        <a href="#about">About Us</a>
                        <a href="#faq">FAQ's</a>
                        <a href="#ourClients">Our Clients</a>
                        <a href="#testimonials">Testimonials</a>
                    </div>
                </div>
                {/** <div className="ourServices">
                    <h1>Our Services</h1>
                    <div className="ourServices_section">
                        <a href="#">Service-1</a>
                        <a href="#">Service-2</a>
                        <a href="#">Service-3</a>
                        <a href="#">Service-4</a>
                    </div>
                </div>
                 */}
                <div className="contactInfo">
                    <h1>Contact Info</h1>
                    <div className="contactInfo_section">
                        <div className="contactInfo_subSection">
                            <img src={image.Call} alt="Call" />
                            <p>1234567890</p>
                        </div>
                        <div className="contactInfo_subSection">
                            <img src={image.Email} alt="Email" />
                            <p>hello@adsimpl.com</p>
                        </div>
                        <div className="contactInfo_subSection">
                            <img src={image.Location} alt="Location" />
                            <p>123, Surat, Gujarat.</p>
                        </div>
                    </div>
                </div>
            </div>

            <a href="#home" className='rocketUpIcon'>
                <img src={image.RocketUpIcon} />
            </a>

            <div className="footer-bottom">
                <p>© 2024 AdSimpl App | All rights reserved</p>
                <div className="rocket-icon">
                    <i className="fas fa-rocket"></i>
                </div>
            </div>
        </div>

    );
};

export default Footer;
