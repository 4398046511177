import React from 'react'
import "./LandingPageNew.scss"
import Header from './Header/Header'
import { image } from "../LandingPageNew/utils/common/image";

import HomeLandingPage from './HomeLandingPage/HomeLandingPage'
import AboutUsLandingPage from './AboutUsLandingPage/AboutUsLandingPage';
import OurClients from './OurClients/OurClients';
import Testimonials from './Testimonials/Testimonials';
import Faqs from './Faqs/Faqs';
import DownloadApp from './DownloadApp/DownloadApp';
import DownloadStores from './DownloadStores/DownloadStores';
import ContactUs from './ContactUs/ContactUs';
import Footer from './Footer/Footer';
const LandingPageNew = () => {
    return (
        <div className='homeLandingPage-Main'>
            <div className='homeLandingPage_container'>
                <HomeLandingPage />
            </div>

            <div className='stores_container'>
                <DownloadStores />
            </div>

            <div className='aboutUsPage'>
                <AboutUsLandingPage />
            </div>


            <div className='ourClients'>
                <OurClients />
            </div>

            <div className='testimonials'>
                <Testimonials />
            </div>
            <div className='faq'>
                <Faqs />
            </div>
            <div className='downloadApp'>
                <DownloadApp />
            </div>
            <div className='contactUs'>
                <ContactUs />
            </div>
            <div className='footer'>
                <Footer />
            </div>
        </div>
    )
}

export default LandingPageNew
