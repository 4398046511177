import React, { useState } from 'react';
import './Header.scss';
import Button from '../atoms/Button/Button';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleLoginClick = () => {
        window.location.href = "https://agency.adsimpl.com/login";

    }

    const handleSignUpClick = () => {
        window.location.href = "https://agency.adsimpl.com/signup"
    }

    return (
        <div className="header-container">
            <div className="header-content">
                <div className={`header-links ${isMenuOpen ? 'open' : ''}`}>
                    <a href="#home" onClick={closeMenu}>Home</a>
                    <a href="#about" onClick={closeMenu}>About</a>
                    <a href="#testimonials" onClick={closeMenu}>Testimonials</a>
                    <a href="#contact" onClick={closeMenu}>Contact</a>
                    <a className={`hamburger_auth ${isMenuOpen ? 'open' : ''}`} href="#" onClick={closeMenu}>Login</a>
                    <a className={`hamburger_auth ${isMenuOpen ? 'open' : ''}`} href="#" onClick={closeMenu}>SignUp</a>
                </div>

                <div className="header-brand">
                    <h1>AdSimpl</h1>
                </div>

                <div className="auth-buttons">
                    <a onClick={handleLoginClick} href="#login">Login</a>
                    <Button onClick={handleSignUpClick} buttonTitle="Sign up" className="sign-up-button" />
                </div>


            </div>
            <div className="hamburger-menu" onClick={toggleMenu}>
                <span className={`line ${isMenuOpen ? 'open' : ''}`}></span>
                <span className={`line ${isMenuOpen ? 'open' : ''}`}></span>
                <span className={`line ${isMenuOpen ? 'open' : ''}`}></span>
            </div>
        </div>
    );
};

export default Header;
